<template>
    <div class="cattle-container">
        <div class="box">
            <img src="https://cdn.9kd.com/kdnet/sem_yangniu/sem_yangniu_750_01.png" alt="">
            <div class="download_btn" @click="toDownload()">
                <img src="https://cdn.9kd.com/kdnet/sem_yangniu/sem_yangniu_btn.png" alt="">
            </div>
            <div class="quan" @click="toDownload()">
                <img src="https://cdn.9kd.com/kdnet/sem_yangniu/sem_yangniu_quanquan.png" alt="">
            </div>
            <div class="shouzhi">
                <img src="https://cdn.9kd.com/kdnet/sem_yangniu/sem_yangniu_shouzhi.png" alt="">
            </div>
        </div>
        <div class="box">
            <img src="https://cdn.9kd.com/kdnet/sem_yangniu/sem_yangniu_750_02.png" alt="">
        </div>
        <div class="box">
            <img src="https://cdn.9kd.com/kdnet/sem_yangniu/sem_yangniu_750_03.png" alt="">
        </div>

        <div class="box">
            <img src="https://cdn.9kd.com/kdnet/sem_yangniu/sem_yangniu_750_04.png" alt="">
        </div>
        <div class="box">
            <img src="https://cdn.9kd.com/kdnet/sem_yangniu/sem_yangniu_750_05.png" alt="">
            <div class="download_red_btn" @click="toDownload()">
                <img src="https://cdn.9kd.com/kdnet/sem_yangniu/sem_yangniu_lingqu.png" alt="">
            </div>
            <div class="go_btn" @click="toDownload()">
                <img src="https://cdn.9kd.com/kdnet/sem_yangniu/sem_yangniu_go.png" alt="">
            </div>
            <div class="hb_btn" @click="toDownload()">
                <img src="https://cdn.9kd.com/kdnet/sem_yangniu/sem_yangniu_hongbao.png" alt="">
            </div>
            <div class="yy_circle_02"></div>
            <div class="yy_circle_01"></div>
        </div>
        <div class="box">
            <img src="https://cdn.9kd.com/kdnet/sem_yangniu/sem_yangniu_750_06.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    methods: {
        toDownload(){
            this.$router.push('/download#ad03')
        },
    },
}
</script>

<style lang="less">
    .cattle-container{
        .box{
            position: relative;
            img{
                display: block;
                width: 100%;
            }
            .download_btn{
                width: 40%;
                position: absolute;
                bottom: 40px;
                left: 50%;
                transform: translate(-50%, 0);
            }
            .quan{
                width: 40px;
                position: absolute;
                bottom: 40px;
                right: 32%;
                z-index: 2;
                opacity: 1;
                animation: blockOrNone 0.5s ease-in-out infinite alternate;
            }
            .shouzhi{
                width: 70px;
                position: absolute;
                bottom: 10px;
                right: 23%;
                z-index: 2;
                animation: topToBottom 0.5s ease-in-out infinite alternate;
            }

            .download_red_btn{
                width: 100%;
                position: absolute;
                top: 0px;
                left: 0;
            }
            .go_btn{
                width: 60px;
                position: absolute;
                top: 30px;
                right: 35px;
                z-index: 2;
                animation: smallToBig 0.6s ease-in-out infinite alternate;
            }
            .yy_circle_01{
                position: absolute;
                top: 27px;
                right: 30px;
                width: 70px;
                height: 70px;
                background: rgba(255, 6, 6, 0.1);
                border-radius: 50%;
                animation: smallToBig 0.6s ease-in-out infinite alternate;
            }
            .yy_circle_02{
                position: absolute;
                top: 22px;
                right: 25px;
                width: 80px;
                height: 80px;
                background: rgba(255, 6, 6, 0.1);
                border-radius: 50%;
                animation: smallToBig 0.6s ease-in-out infinite alternate;
            }
            .hb_btn{
                width: 40px;
                position: absolute;
                top: 10px;
                right: 70px;
                animation: topLeftToBottomRight 0.4s ease-in-out infinite alternate;
            }
        }

    }
</style>

<style>
    @keyframes topToBottom
    {
        from {
            bottom: 10px;
            right: 23%;
        }
        to {
            bottom: -10px;
            right: 19%;
        }
    }
    @keyframes blockOrNone
    {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    @keyframes smallToBig
    {
        from {
            transform: scale(0.8);
        }
        to {
            transform: scale(1);
        }
    }
    @keyframes topLeftToBottomRight
    {
        from {
            top: 10px;
            right: 70px;
        }
        to {
            top: 20px;
            right: 60px;
        }
    }
</style>
